<!--
 * @Description: 批量设置房型
 * @Author: 琢磨先生
 * @Date: 2022-08-19 22:02:53
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-21 00:25:18
-->
<template>
  <el-button icon="EditPen" size="small" round @click="openDrawer">批量设置房型</el-button>

  <el-drawer
    title="批量设置房间归属房型"
    v-model="visible"
    direction="rtl"
    size="1000px"
    :destroy-on-close="true"
    :show-close="true"
    custom-class="drawer_house_batch_shop"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      label-position="top"
      :inline="false"
    >
      <!-- <el-form-item label="门店" prop="shop_id">
        <el-select v-model="form.shop_id" @change="batchShopChange">
          <el-option :label="item.name" :value="item.id" v-for="item in shops" :key="item.id"></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="房型" prop="group_id">
        <el-col :span="10">
          <el-select v-model="form.group_id" placeholder>
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in group_list"
              :key="item"
            ></el-option>
          </el-select>
          <!-- <el-cascader
            :disabled="!form.shop_id"
            v-model="form.groupIds"
            :options="group_options"
            :props="{ checkStrictly: true,}"
            style="width:100%;"
          />-->
        </el-col>
      </el-form-item>

      <el-form-item label="房屋选择">
        <el-table
          ref="shopHouseTable"
          class="shop_house_table"
          :data="store_list"
          @selection-change="selectionChange"
          height="450px"
          row-key="id"
        >
          <el-table-column type="selection" width="50" reserve-selection></el-table-column>
          <el-table-column label="编号" prop="id" width="100"></el-table-column>
          <el-table-column>
            <template #header>
              <el-input v-model="query.q" placeholder="请输入房号" @input="keywordChange"></el-input>
            </template>
            <template #default="scope">
              <span>
                {{scope.row.house_no}}(房东:{{scope.row.landlord.name}})、
                <el-link type="primary" v-if="scope.row.group">户型：{{scope.row.group?.name}}</el-link>
                <el-link type="danger" v-else>尚未分组</el-link>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <div>
        <el-alert
          type="info"
          :closable="false"
          :title="`已选 ${selectionList.length} 个房源`"
          style="margin-bottom:10px;"
        >
          <el-tag
            :closable="true"
            v-for="(item, i) in selectionList"
            :key="item.id"
            style="margin-right: 20px; margin-bottom: 10px"
            @close="removeSelectionItem(item,i)"
          >
            <b>{{item.id}}</b>
            、{{ item.house_no
            }} (房东:{{item.landlord.name}})
          </el-tag>
        </el-alert>
        <el-button type="info" link @click="clearSelection">清除全部</el-button>
      </div>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      saving: false,
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //全部的房屋列表
      store_list: [],
      //已选择的房源列表
      selectionList: [],
      form: {},
      rules: {
        shop_id: [{ required: true, message: "必选", trigger: "blur" }],
        group_id: [{ required: true, message: "必选", trigger: "blur" }],
      }, 
      //户型
      group_list: [],
    };
  },
  props: ["shops", "groups"],
  emits: ["change"],
  created() {},
  watch: {
    groups: {
      handler() {
        // if (this.groups) {
        //   var list = this.groups.filter((o) => !o.father_id);
        //   this.cascader_options = [];
        //   list.forEach((item) => {
        //     var father = {
        //       value: item.id,
        //       label: item.name,
        //       shop_id: item.shop_id,
        //       children: [],
        //     };
        //     this.groups
        //       .filter((o) => o.father_id == item.id)
        //       .forEach((x) => {
        //         father.children.push({
        //           value: x.id,
        //           label: x.name,
        //         });
        //       });
        //     this.cascader_options.push(father);
        //   });
        // }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 打开弹出框
     */
    openDrawer() {
      this.form = {};
      // this.refreshStore();
      this.visible = true;
      this.loadHuxing();
      this.loadData();
    },

    /**
     * 获取房型
     */
    loadHuxing() {
      this.$http.get("seller/v1/houseStore/huxing").then((res) => {
        if (res.code == 0) {
          this.group_list = res.data;
        }
      });
    },

    /**
     * 关键输入
     * @param {*} val
     */
    keywordChange(val) { 
      this.store_list = this.tableData.filter((o) => o.house_no.indexOf(val) > -1);
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.get("seller/v1/houseStore/stores").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.store_list = this.tableData;
          // this.refreshStore();
        }
        this.loading = false;
      });
    },

    /**
     * 门店选择更改
     */
    // batchShopChange(val) {
    //   this.form.group_id = '';
    //   this.group_options = this.groups.filter((o) => o.shop_id == val);
    //   this.refreshStore();
    //   this.selectionList = [];
    //   this.$refs.shopHouseTable.clearSelection();
    // },

    /**
     * 刷新房屋列表
     */
    // refreshStore() {
    //   if (this.form.shop_id) {
    //     this.store_list = this.tableData.filter(
    //       (o) => o.house.shop_id == this.form.shop_id
    //     );
    //   } else {
    //     this.store_list = [];
    //   }
    // },

    /**
     * 表格选择发生变化
     */
    selectionChange(nodes) {
      this.selectionList = nodes;
    },

    /**
     * 移除已经选择的项
     * @param {*} item
     * @param {*} i
     */
    removeSelectionItem(item, i) {
      this.selectionList.splice(i, 1);
      this.$refs.shopHouseTable.toggleRowSelection(item, false);
    },
    /**
     * 清除全部选择
     */
    clearSelection() {
      this.selectionList = [];
      this.$refs.shopHouseTable.clearSelection();
    },
    /**
     * 提交保存
     */
    onSubmit() {
      if (this.selectionList.length == 0) {
        this.$message.error("请选择房源");
        return;
      }

      this.form.storeIds = this.selectionList.map((x) => x.id);
      // this.form.group_id = this.form.groupIds[this.form.groupIds.length - 1];

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/HouseStore/huxing/set", this.form)
            .then((res) => {
              if (res.code == 0) {
                // this.visible = false;
                this.$message.success(res.msg);
                this.loadData();
                this.$emit("change");
              } else {
                this.$message.error(res.msg);
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style >
.shop_house_table.el-table .cell {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>